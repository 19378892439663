import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Seo from "./Head";
import { CartContext } from "../context/CartContext";
import { getItems } from "./apiCrudRealTime";

const Tienda = () => {
  const [categories, setCategories] = useState([]);
  const cartContext = useContext(CartContext);
  const { items } = cartContext;

  useEffect(() => {
    getItems()
      .then((categorias) => {
        const filtro = categorias.filter(
          (item) =>
            item.hasOwnProperty("categoria") &&
            item.categoria.toLowerCase() !== "talleres"
        );
        setCategories(filtro);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <Seo
        title="Tienda Espacio Analógico"
        description="Rollos, papeles, libros, talleres"
        image={`${window.location.origin}/static/media/LogoNuevo.c219d0bf4f0348ebfdc3.png`}
        pathSlug={window.location.href}
      />

      <div className="item row">
        {categories
          .filter((cat) =>
            items.some(
              (item) =>
                item.categoria === cat.categoria && item.imagenes?.[0]?.url
            )
          )
          .map((cat) => {
            const item = items.find((item) => item.categoria === cat.categoria);

            return (
              <div
                key={cat.id}
                className="col-12 col-md-4
"
              >
                <div className="card">
                  <div className="talleres cajaDeCajas">
                    <Link to={`/tienda/${cat.categoria}`}>
                      <div className="cajaDeCajas">
                        <div className="imgMedida caja">
                          <div className="prueba img-container">
                            <img
                              src={item?.imagenes?.[0]?.url}
                              alt={`Foto de ${item?.titulo}`}
                              className="img-fluid mx-0 px-0"
                            />
                          </div>
                        </div>

                        <h4 className="text-center negrita mt-2 pb-2">
                          {cat.categoria}
                        </h4>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Tienda;
